import { remoteJobsCategorySlugs } from '@components/common/constant';
import { ToggleIcon } from '@components/jobSearch/styled';
import { ArrowIcon } from '@components/jobsSection/styled';
import { useMemo, useState } from 'react';
import { ContentWrapper, JobsToggleBtn, TitleWrap } from './styled';
const JobListLink = [
  {
    title: 'Remote Companies Q&A',
    subList: [
      {
        title: 'Why Remote',
        link: '/qa-leading-remote-companies/why-remote',
      },
      {
        title: 'Hiring Remotely',
        link: '/qa-leading-remote-companies/hiring-remotely',
      },
      {
        title: 'Managing Remotely',
        link: '/qa-leading-remote-companies/managing-remotely',
      },
      {
        title: 'Working Remotely',
        link: '/qa-leading-remote-companies/operating-remotely',
      },
      {
        title: 'Remote Worker Insights',
        link: '/qa-leading-remote-companies/remote-worker-insights',
      },
      {
        title: 'All Questions & Companies',
        link: '/qa-leading-remote-companies',
      },
    ],
  },
  {
    title: 'Remote Workers Q&A',
    subList: [
      {
        title: 'All Questions & Workers',
        link: '/remote-workers',
      },
    ],
  },
  {
    title: 'Remote Articles',
    subList: [
      {
        title: 'Why Go Remote',
        link: '/category/why-go-remote',
      },
      {
        title: 'Build a Remote Team',
        link: '/category/build-a-remote-team',
      },
      {
        title: 'Remote Management Insights',
        link: '/category/remote-management',
      },
      {
        title: 'Work Remotely',
        link: '/category/work-remotely',
      },
    ],
  },
  {
    title: 'Remote Jobs',
    subList: [],
  },
];

const JobsActionLink = ({ isRCPortal, isRemoteJobsPage }: any) => {
  const [visibleIndexes, setVisibleIndexes] = useState<number[]>([]);

  const toggleVisibility = (index: number) => {
    setVisibleIndexes((prev) => {
      if (prev?.includes(index)) {
        return prev.filter((i) => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };

  const updatedJobList = useMemo(() => {
    const remoteJobLinks = remoteJobsCategorySlugs.map(
      ({ heading, headingLink }) => ({
        title: heading,
        link: headingLink,
      })
    );
    return JobListLink.map((job) =>
      job.title === 'Remote Jobs' ? { ...job, subList: remoteJobLinks } : job
    );
  }, [remoteJobsCategorySlugs]);

  return (
    <ContentWrapper isRCPortal={isRCPortal}>
      {updatedJobList.map((jobsLink, index) => (
        <li key={index} className="action-item">
          <TitleWrap
            as="button"
            isHeading
            onClick={() => toggleVisibility(index)}
            isRCPortal={isRCPortal}
            className="action-link"
          >
            {jobsLink.title}{' '}
            {isRCPortal && (
              <ArrowIcon
                isToggle={visibleIndexes?.includes(index)}
                isRCPortal={isRCPortal}
                isRemoteJobsPage={isRemoteJobsPage}
              />
            )}
            {!isRCPortal && (
              <JobsToggleBtn role="button" aria-label="toggle job">
                <ToggleIcon
                  isAccordionIcon
                  isToggle={visibleIndexes?.includes(index)}
                />
              </JobsToggleBtn>
            )}
          </TitleWrap>
          {visibleIndexes?.includes(index) && jobsLink.subList && (
            <ContentWrapper subList>
              {jobsLink.subList.map((linkList, subIndex) => (
                <li key={subIndex}>
                  <TitleWrap href={linkList.link} isRCPortal={isRCPortal}>
                    {linkList.title} <ArrowIcon isRCPortal={isRCPortal} />
                  </TitleWrap>
                </li>
              ))}
            </ContentWrapper>
          )}
        </li>
      ))}
    </ContentWrapper>
  );
};
export default JobsActionLink;
